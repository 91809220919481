<template>
  <div class="unassign-referral-sidebar">
    <div class="unassign-referral-sidebar--content">
      <div style="display: flex; align-items: center; padding-top: 15px">
        <CRIcon view-box="0 0 24 24" :width="20" :height="20" color="red">
          alert_outline
        </CRIcon>
        <p style="padding-left: 8px; color: #e10055; margin: 0">
          {{ isAccepted ? 'Unassign' : 'Reject' }}
          <b>{{ referral.companyName }}</b>
          for {{ reservation.managedId }}?
        </p>
      </div>
      <div v-if="isAccepted">
        <div style="margin-top: 20px">
          <ClassificationSelector
            id="-operator-cancellation-classification-selector"
            ref="operator-cancellation-classification-selector"
            v-model="classificationId"
            :initial-classification="classificationId"
            :classification-type-id="4"
            :mode="isRequestedCancel ? 'view' : ''"
            classification-required
          />
        </div>
        <v-checkbox
          v-if="!reservation.isPreBooking"
          :id="`charge-operator-cancellation-penalty`"
          v-model="chargeOperatorCancellationPenalty"
          label="Charge Operator for Cancellation"
          style="margin-top: 0"
          hide-details
        />
      </div>
    </div>
    <div v-if="isAccepted">
      <CollectPaymentNew
        v-show="chargeOperatorCancellationPenalty"
        :id="`operator-cancellation-penalty`"
        ref="collect-payment-new"
        v-bind="$attrs"
        :balance="amount"
        :default-selected-payment-method="PaymentMethodTypeKey.CreditCard"
        :operator-payment-profiles="operatorPaymentProfiles"
        :default-selected-card="defaultSelectedCard"
        :tooltip-text="tooltipText"
        :tooltip-style="{ 'white-space': 'pre-line' }"
        is-operator-cancel
        @selected-card="setPaymentProfile"
        @amount-updated="updateAmount"
        @new-card-selected="newCardSelected"
      ></CollectPaymentNew>
    </div>
    <div>
      <CRButton
        id="unassign-referral-save-btn"
        :loading="loading"
        class="unassign-referral-sidebar"
        :class="{
          'charge-btn': !isNewCardSelected && chargeOperatorCancellationPenalty,
          'action-btn': !chargeOperatorCancellationPenalty || isNewCardSelected,
        }"
        :color="buttonColor"
        text-color="white"
        @click="submit"
      >
        {{ buttonLabel }}
      </CRButton>
    </div>
  </div>
</template>

<script>
import operatorCancel from '@/services/operatorCancel'
import ticket from '@/services/ticket'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'
import ClassificationSelector from '@/components/ClassificationSelector.vue'
import CollectPaymentNew from '@/components/CollectPaymentNew.vue'
import { currencyFilter } from '@/utils/currency'
import { mapActions, mapGetters } from 'vuex'
import { getCardType } from '@/utils/creditCard'
import { PaymentMethodTypeKey, TicketStatusTypeId } from '@/utils/enum'

export default {
  components: {
    ClassificationSelector,
    CollectPaymentNew,
  },
  props: {
    reservation: {
      type: Object,
      default: () => {},
    },
    referral: {
      type: Object,
      default: () => {},
    },
    ticketId: {
      type: Number,
      default: null,
    },
    newTicketComment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chargeOperatorCancellationPenalty: true,
      classificationId: null,
      minAmount: 0,
      amount: 0,
      operatorPaymentProfiles: [],
      operatorPaymentProfileId: null,
      loading: false,
      isNewCardSelected: false,
      defaultSelectedCard: null,
      tooltipText: `More than 30 days: No Fee
            30 days to 15 days: $250/vehicle/day
            14 days - 24 Hours: $500/vehicle/day
            Less than 24 hours: Minimum $750/vehicle/day
            (or full value of the trips)`,
      PaymentMethodTypeKey: PaymentMethodTypeKey,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      isReferralRejectionReasonV2Enabled:
        'featureToggles/isReferralRejectionReasonV2Enabled',
    }),
    isAccepted() {
      return this.referral?.referralStatus === 'accepted'
    },
    isRequestedCancel() {
      return this.referral?.operatorCancellationStatusKey === 'requested'
    },
    buttonLabel() {
      if (!this.isAccepted) {
        return 'Reject'
      } else {
        if (this.chargeOperatorCancellationPenalty) {
          if (this.isNewCardSelected) {
            return 'Add New Card'
          }
          return `Charge ${currencyFilter(this.amount)}`
        }
        return 'Unassign'
      }
    },
    buttonColor() {
      return this.chargeOperatorCancellationPenalty && !this.isNewCardSelected ? 'success' : 'primary'
    },
  },
  async created() {
    this.chargeOperatorCancellationPenalty =
      this.isAccepted
    if (this.isAccepted) {
      this.getOperatorCancellationPenaltyInfo()
      if (this.isRequestedCancel) {
        this.classificationId = this.referral?.operatorCancellationClassificationId
        this.operatorPaymentProfileId = this.referral?.operatorCancellationSelectedPaymentMethodId
      }
      await this.getOperatorPaymentProfiles()
      const foundCard = this.operatorPaymentProfiles.find(
        (profile) =>
          profile.operatorPaymentProfileId === this.operatorPaymentProfileId
      )
      if (foundCard) {
        this.defaultSelectedCard = foundCard
      }
    }
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    getCardType,
    newCardSelected(e) {
      this.isNewCardSelected = e
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async getOperatorCancellationPenaltyInfo() {
      if (!this.referral?.reservationId) {
        return
      }
      const payload = await operatorCancel.getOperatorCancellationPenaltyInfo(
        this.referral.reservationId
      )
      this.amount = payload?.data?.cancellationInfoDTO?.totalPenalty
      this.minAmount = this.amount
    },
    async getOperatorPaymentProfiles() {
      if (!this.referral?.companyId) {
        return
      }
      const payload = await this.$store.dispatch(
        'payments/getOperatorPaymentProfiles',
        this.referral.companyId
      )
      this.operatorPaymentProfiles = payload?.data?.operatorPaymentProfiles.map(
        (profile) => {
          return {
            ...profile,
            typeLabel: profile?.label,
          }
        }
      )
    },
    setPaymentProfile(creditCard) {
      if (creditCard?.newCard) {
        this.newCard = creditCard.newCard
      } else {
        this.operatorPaymentProfileId = creditCard?.operatorPaymentProfileId
      }
    },
    updateAmount(newAmount) {
      this.amount = parseInt(newAmount)
    },
    async createOperatorPaymentProfile() {
      const cardForm = this.$refs['collect-payment-new']?.$refs['credit-card-selector']
      const tokenizeResponse = await cardForm.tokenizeCard()
      const { paymentGateway, tokens, formattedPaymentInfo } = tokenizeResponse

      let payload = {
        ...formattedPaymentInfo?.billing,
        payment_gateway: paymentGateway,
        nonces: tokens,
        companyId: 2,
        operatorCompanyId: this.referral.companyId,
        showOnCharterUp: false,
      }

      payload.cardExpirationDate = payload.exp_date
      payload.cardLabel = payload.type_label
      payload.address.postalCode = payload.address.postal_code
      payload.address.timeZone = payload.address.time_zone

      try {
        const response = await this.$store.dispatch(
          'payments/createOperatorPaymentProfile',
          payload
        )
        return response?.data?.operatorPaymentProfileId
      } catch (error) {
        this.loading = false
        this.showAlert({
          type: 'error',
          message: 'Could not create card with given information.',
        })
        return false
      }
    },
    async submit() {
      this.loading = true
      const unassignReferralPayload = {
        reservationId: this.referral.reservationId,
      }

      if (this.isNewCardSelected && this.chargeOperatorCancellationPenalty) {
        if (!this.$refs['collect-payment-new'].validateCreditCardSelector()) {
          this.showAlert({
            type: 'error',
            message: 'Invalid credit card information.',
          })
          this.loading = false
          return
        }

        const newOperatorPaymentProfileId = await this.createOperatorPaymentProfile()
        await this.getOperatorPaymentProfiles()
        if (newOperatorPaymentProfileId) {
          const foundCard = this.operatorPaymentProfiles.find(
            (profile) =>
              profile.operatorPaymentProfileId === newOperatorPaymentProfileId
          )
          if (foundCard) {
            this.defaultSelectedCard = foundCard
          }
        }
        this.loading = false
        return
      }

      const classificationSelector = this.$refs['operator-cancellation-classification-selector']
      if (!classificationSelector.validate()) {
        this.loading = false
        return
      }

      if (this.chargeOperatorCancellationPenalty) {
        if (!this.operatorPaymentProfileId) {
          this.showAlert({
            type: 'error',
            message: 'Must select credit card.',
          })
          this.loading = false
          return
        }

        const payload = {
          reservationId: this.referral?.reservationId,
          cancellationPenaltyInput: this.amount,
          operatorCancellationClassificationId: this.classificationId,
          operatorPaymentProfileId: this.operatorPaymentProfileId,
        }

        if (!this.isRequestedCancel) {
          try {
            await operatorCancel.operatorRequestCancel(payload)
          } catch (error) {
            this.loading = false
            this.showAlert({
              type: 'error',
              message: 'Issue charging cancellation penalty.',
            })
            return false
          }
        } else {
          try {
            await operatorCancel.operatorCancelConfirm(payload)
          } catch (error) {
            this.loading = false
            this.showAlert({
              type: 'error',
              message:
                error?.response?.data?.message ||
                'Issue confirming the cancellation request.',
            })
            return false
          }
        }
      } else {
        unassignReferralPayload.operatorCancellationClassificationId = this.classificationId
        try {
          if (this.isReferralRejectionReasonV2Enabled) {
            await this.$store.dispatch(
              'reservations/unassignReferralV2',
              unassignReferralPayload
            )
          } else {
            await this.$store.dispatch(
              'reservations/unassignReferral',
              unassignReferralPayload
            )
          }
        } catch (error) {
          this.loading = false
          this.showAlert({
            type: 'error',
            message: 'Issue unassigning the referral.',
          })
          return false
        }
      }

      if (this.ticketId) {
        let payload = {
          sendEmail: false,
          subTickets: [this.newTicketComment],
        }
        if (!this.chargeOperatorCancellationPenalty) {
          payload.ticketStatusTypeId = TicketStatusTypeId.Closed
        }

        try {
          await ticket.partialUpdate({
            id: this.ticketId,
            payload,
          })
          this.$store.dispatch(
            'app/showAlert',
            { message: 'Ticket updated.' },
            { root: true }
          )
        } catch (error) {
          this.loading = false
          this.showAlert({
            type: 'error',
            message: 'Issue closing the ticket.',
          })
          return false
        }
        EventBus.$emit('refresh-tickets')
      }

      EventBus.$emit('refresh-detail')
      EventBus.$emit('refresh-reservation-detail')
      EventBus.$emit('refresh-reservation-payments')
      this.loading = false
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.unassign-referral-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px 40px 0 40px;
  }
}

.action-btn {
  display: flex;
  position: fixed;
  flex-direction: column;
  font-size: 18px;
  bottom: 0;
  width: 500px !important;
  height: 71px !important;
  padding: 24px 0;
  width: inherit;
  border-radius: 0;
  background: $primary;
  z-index: 3;
}

.charge-btn {
  display: flex;
  position: fixed;
  flex-direction: column;
  background: $success;
  font-size: 18px;
  color: white;
  bottom: 0;
  padding: 24px 0;
  width: 500px !important;
  height: 71px !important;
  text-align: center;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out;
  border-radius: 0;
  z-index: 3;
}

::v-deep .accent--text {
  color: $blue !important;
}
</style>
